<template>
  <div>
    <div id="aiEditor" style="height: 550px;  margin: 20px;width: 100%;"></div>
  </div>
</template>

<script>
import { reactive, onMounted, ref, watch, toRefs, nextTick } from "vue";
import { AiEditor } from './index.js'
import { getUrl } from "@/api/test.js";
export default {
  name: "aiEditor",
  props: {
    value: {
      type: String,
      default: ''
    },
    //获取编辑栏是否禁用
    disable: {
      type: Boolean,
    }
  },
  emits: ['valueChange'],
  setup(props, { emit }) {
    onMounted(() => {
      data.editor = new AiEditor({
        element: "#aiEditor",
        placeholder: "点击输入内容...",
        content: props.value,
        editable: !props.disable,
        onChange: (editor) => {
          // 监听到用编辑器内容发生变化了，控制台打印编辑器的 html 内容...
          const html = editor.getHtml();
          emit('valueChange', html)
        },
        ai: {
          models: {
            spark: {
              appId: "91851fb4",
            },
          },
          onCreateClientUrl: (modelName, modelConfig, startFn) => {
            getUrl({ appId: '91851fb4' }).then(res => {
              startFn(res.data.url)
            })
          },

        },
      })

    })

    const onChange = () => {
      const html = data.editor.getHtml();
      emit('valueChange', html)
    };

    const data = reactive({
      editor: null,
      content: '',
    });



    return {
      onChange,
    };
  },
};
</script>

<style>
@import './style.css';
</style>

<style lang="less" scoped></style>
